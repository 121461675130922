import React, { useState ,useEffect} from "react";
import {
  TextField,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const useStyles = makeStyles({
  root: {
    // padding: theme.spacing(2),
    // maxWidth: 800,
    margin: 100,
    backgroundColor:"#1a191e !important",

  },
  textField: {
    backgroundColor: "white !important",
    borderRadius: "15px !important",
    // marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop:"20px !important",
    // marginTop: theme.spacing(2),
  },
  gridContainer: {
    minHeight: '100vh', // Postavite visinu kontejnera na visinu ekrana
  },
  gridItemLeft: {
    position: "fixed",
    height: '100%', // Fiksna visina za Grid sa xs={3}
    backgroundColor: 'lightgray', // Boja pozadine po potrebi
  },
  gridItemRight: {
    // marginLeft: 0, // Margina sa lijeve strane, prilagodite po potrebi
    // paddingLeft:'0px !important',
    backgroundColor:"#1a191e",
    minHeight: '100vh !important',
    height: '100% !important', 
    display:'block',
    padding:20
  },
  slikaVijestNaslov: {
    color: "white",
    textAlign: 'left',
    backgroundColor:"#1a191e",
    // marginTop:"5%"
  },
  slikaVijestTekst: {
    color: "#b0b0b0",
    textAlign: 'justify',
  }
});

const ContactForm = () => {
    const classes = useStyles();
    const { t } = useTranslation();
  
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      message: "",
    });
  
    const [answer, setAnswer] = useState(""); 
    const [number1, setNumber1] = useState(""); // Stanje za odgovor na zagonetku
    const [number2, setNumber2] = useState(""); // Stanje za odgovor na zagonetku
    const [isAnswerCorrect, setIsAnswerCorrect] = useState(""); // Stanje za provjeru točnosti odgovora
  
    useEffect(() => {
      // Generirajte slučajne brojeve samo pri prvom renderiranju komponente
      const { number1, number2 } = generateRandomNumbers();
      // Postavite početne vrijednosti brojeva u input polju za odgovo
      setNumber1(number1)
      setNumber2(number2)
    //   setIsAnswerCorrect()
    //   setAnswer(number1 + number2);
    }, []);
  
    const generateRandomNumbers = () => {
      // Generirajte dva slučajna broja između 1 i 10
      const number1 = Math.floor(Math.random() * 10) + 1;
      const number2 = Math.floor(Math.random() * 10) + 1;
      return { number1, number2 };
    };
  
    // const { number1, number2 } = generateRandomNumbers();
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      // Provjerite je li odgovor na zagonetku točan
      if (parseInt(isAnswerCorrect) === number1 + number2) {
        // Ovdje možete dodati logiku za slanje forme ili obradu podataka
        try {
          const response = await axios.post('https://app.almaf.ba/wp-json/custom/v1/send-email', {
            name: formData.name,
            email: formData.email,
            message: formData.message,
          });
          // console.log(response.data)
          if (response.data.message === "Email uspješno poslan.") {
            // alert(response.data.data);
            alert('Email uspješno poslan');
          } else {
            alert('Greška prilikom slanja e-maila.');
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        alert(`${t('pogresanOdg')}`);
      }
    };
  
  return (
    <Box sx={{ flexGrow: 1 }} style={{backgroundColor: '#1a191e', marginTop:"5%"}} className="kontaktWeb">
    <Grid container spacing={2} className={classes.gridContainer}>
      {/* <Grid item xs={3} className={classes.gridItemLeft}>
        <LeftSidebar/>
      </Grid> */}
      <Grid item xs={12} className={`${classes.gridItemRight} webVerzijaKontakt`}>
    <Paper elevation={3} className="webVerzijaKontakt">
      <Typography variant="h5" gutterBottom className={classes.slikaVijestNaslov}>
        {t('contactme')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              variant="outlined"
              fullWidth
              label= {t('name')}
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              variant="outlined"
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              variant="outlined"
              fullWidth
              label= {t('message')}
              name="message"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
          <Typography variant="body1" gutterBottom className={classes.slikaVijestTekst}>
          {t('whatis')} {number1} + {number2}?
      </Typography>
      </Grid>
      <Grid item xs={3}>
      <TextField
        className={classes.textField}
        variant="outlined"
        fullWidth
        label=  {t('response')}
        name="answer"
        type="number"
        value={isAnswerCorrect}
        onChange={(e) => setIsAnswerCorrect(e.target.value)}
        required
      />
       </Grid>
        </Grid>
        <Button
          className={classes.submitButton}
          variant="contained"
          color="primary"
          type="submit"
        >
          {t('send')}
        </Button>
      </form>
    </Paper>
    </Grid>
      </Grid>
    </Box>
  );
};

export default ContactForm;
