import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    lng: 'en', // Zadani jezik
    resources: {
      en: {
        translation: {
          greeting: 'Hello',
          home: 'Home',
          bio: 'Biography',
          gallery: 'Gallery',
          news: 'News',
          readMore:'Read more',
          contact: 'Contact',
          contactme:'Contact me',
          message:'Message',
          send:"Send",
          whatis:"What is?",
          pogresanOdg:"Wrong answer!",
          loadmore:"Load More",
          english: 'English',
          response:"Response",
          bosnian: 'Bosnian',
          name:'Name',
        },
      },
      bs: {
        translation: {
          greeting: 'Pozdrav',
          home: 'Početna',
          bio: 'Biografija',
          gallery: 'Galerija',
          news: 'Vijesti',
          readMore:'Pročitaj više',
          contact: 'Kontakt',
          contactme:'Kontaktirajte me',
          whatis:"Koliko je?",
          send:'Pošalji',
          response:"Odgovor",
          name:'Ime',
          pogresanOdg:"Odgovor nije tačan!",
          message:'Poruka',
          loadmore:"Učitaj više",
          english: 'Engleski',
          bosnian: 'Bosanski'
        },
      },
      // Dodajte više jezika i prijevoda po potrebi
    },
  });

export default i18n;
