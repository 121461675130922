import React,{useState,useEffect} from 'react';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { FadeLoader } from "react-spinners"; 

const useStyles = makeStyles({
  videoBox: {
    width: '48%', // Željena širina kutije
    margin: '1%', // Razmak između kutija
    float: 'left', // Kutije će biti u istom redu
    boxSizing: 'border-box',
  },
  videoContainer: {
    position: 'relative',
    paddingBottom: '56.25%', // Omjer širine i visine za video (16:9)
    height: 0,
    overflow: 'hidden',
  },
  videoIframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

const YouTubeVideoBoxes = () => {
  const classes = useStyles();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
//   const postId = 10 // Pretpostavka da je ID posta dostupan preko parametra rute
  const id = 13;
  const apiUrl = `https://app.almaf.ba/wp-json/wp/v2/pages/52`;
  const storedLanguage = localStorage.getItem('selectedLanguage');
  useEffect(() => {
    // Definiramo funkciju za izradu GET zahtjeva
    const fetchPost = async () => {
      try {
        const response = await axios.get(apiUrl);
        setPost(response.data); // Postavljamo dohvaćeni post u state
        setLoading(false);
      } catch (error) {
        console.error('There was a problem with the request:', error);
      }
    };

    // Pozivamo funkciju za dohvaćanje posta kada komponenta bude montirana
    fetchPost();
  }, [apiUrl]); // Ovisnost o URL-u posta
// console.log("post.post",post?.acf?.video_2
// )
  return (
    <>
    {loading ? (
      <div
      className="text-center"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
      }}
    >
      <FadeLoader color={"#123abc"} loading={loading} size={100} />
    </div>
  ) : (
    <div>
      <div className={classes.videoBox}>
        <div className={classes.videoContainer}>
          
          <iframe
            className={classes.videoIframe}
            src={`https://www.youtube.com/embed/${post?.acf?.video_2}`}
            frameborder="0"
            allowfullscreen
            title="YouTube Video 1"
          ></iframe>
        </div>
      </div>
      <div className={classes.videoBox}>
        <div className={classes.videoContainer}>
          <iframe
            className={classes.videoIframe}
            src={`https://www.youtube.com/embed/${post?.acf?.video_3}`}
            frameborder="0"
            allowfullscreen
            title="YouTube Video 2"
          ></iframe>
        </div>
      </div>
      <div className={classes.videoBox}>
        <div className={classes.videoContainer}>
          <iframe
            className={classes.videoIframe}
            src={`https://www.youtube.com/embed/${post?.acf?.video_4}`}
            frameborder="0"
            allowfullscreen
            title="YouTube Video 3"
          ></iframe>
        </div>
      </div>
      <div className={classes.videoBox}>
        <div className={classes.videoContainer}>
          <iframe
            className={classes.videoIframe}
            src={`https://www.youtube.com/embed/${post?.acf?.video_5}`}
            frameborder="0"
            allowfullscreen
            title="YouTube Video 4"
          ></iframe>
        </div>
      </div>
    </div>
       )}
    </>
  );
};

export default YouTubeVideoBoxes;
