import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import parse from 'html-react-parser';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FadeLoader } from "react-spinners"; 

const useStyles = makeStyles({
  // ...ostali stilovi
  gallery: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
  },
  galleryImage: {
    maxWidth: '100%',
    height: 'auto',
    cursor: 'pointer',
  },
  loadMoreButton: {
    marginTop: '20px',
  },
  gridContainer: {
    minHeight: '100vh', // Postavite visinu kontejnera na visinu ekrana
  },
  gridItemLeft: {
    position: "fixed",
    height: '100%', // Fiksna visina za Grid sa xs={3}
    backgroundColor: 'lightgray', // Boja pozadine po potrebi
  },
  gridItemRight: {
    marginLeft: '25%', // Margina sa lijeve strane, prilagodite po potrebi
    // paddingLeft:'0px !important',
    backgroundColor:"#1a191e",
    minHeight: '100vh !important',
    height: '100% !important', 
    display:'block',
    padding:20
  },
  slikaVijestNaslov: {
    color: "white",
    textAlign: 'left',
  },
  slikaVijestTekst: {
    color: "#b0b0b0",
    textAlign: 'justify',
  },
   // Dodajte stil za sakrivanje klizača
   imageListContainer: {
    overflow: 'hidden', // Sakrijte preljevne kontrole
  },
});

const Galerija = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [post, setPost] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [visibleImages, setVisibleImages] = useState(16);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setIsLoading] = useState(true);

  // const id = 13;
  const apiUrl = `https://app.almaf.ba/wp-json/wp/v2/pages/20`;

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(apiUrl);
        setPost(response.data);
        setIsLoading(false); 
      } catch (error) {
        console.error('There was a problem with the request:', error);
      }
    };
  
    fetchPost();
  }, [apiUrl]);
  
  // if (!post) {
  //   return <div>Loading...</div>;
  // }
  
  const parser = new DOMParser();
  const htmlDoc = parser?.parseFromString(post?.content?.rendered, 'text/html');
  const allImages = Array.from(htmlDoc.querySelectorAll('img'));

  const loadMoreImages = () => {
    setIsLoading(true);
    setTimeout(() => {
      const newVisibleImages = visibleImages + 16;
      if (newVisibleImages >= allImages.length) {
        setHasMore(false);
      }
      setVisibleImages(newVisibleImages);
      setIsLoading(false);
    }, 1000); // Simuliramo vreme učitavanja slika
  };

  const imagesToDisplay = allImages.slice(0, visibleImages).map((img, index) => (
    <img
      key={index}
      src={img.getAttribute('src')}
      alt=""
      className={classes.galleryImage}
      onClick={() => handleImageClick(img.getAttribute('src'))}
    />
  ));
  
  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };
  
  const handleClose = () => {
    setSelectedImage(null);
    setOpen(false);
  };
  
  return (
    <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: '#1a191e',marginLeft:"22%" }}>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12} className={`${classes.gridItemRight} webVerzijaGalerija`}>
        {loading ? (
      <div
      className="text-center"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
      }}
    >
      <FadeLoader color={"#123abc"} loading={loading} size={100} />
    </div>
  ) : (
          <CardContent>
            <Typography gutterBottom variant="h4" component="div" className={classes.slikaVijestNaslov}>
              {parse(post.title.rendered)}
            </Typography>
            <div style={{ overflow: 'hidden' }}>
            <InfiniteScroll
              dataLength={visibleImages}
              next={loadMoreImages}
              // hasMore={hasMore}
              loader={<FadeLoader />}
              sx={{
                overflow: 'hidden', // Uklanja overflow stil iz InfiniteScroll komponente
              }}
            >
             <ImageList
              sx={{
                width: '100%',
                overflowY: 'hidden', // Uklanja overflow-y stil
              }}
              cols={4}
              rowHeight={200}
            >
                {imagesToDisplay.map((image, index) => (
                  <ImageListItem key={index} cols={1} rows={1} >
                    {image}
                  </ImageListItem>
                ))}
              </ImageList>
            </InfiniteScroll>
            </div>
            {hasMore && (
              <Button
                variant="contained"
                color="primary"
                onClick={loadMoreImages}
                className={classes.loadMoreButton}
                disabled={loading}
              >
                {loading ? <FadeLoader size={20} /> : `${t('loadmore')}` }
              </Button>
            )}
            
            <Dialog open={open} onClose={handleClose}>
              <DialogContent>
                <img src={selectedImage} alt="" style={{ maxWidth: '100%', height: 'auto' }} />
              </DialogContent>
            </Dialog>
            
          </CardContent>
           )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Galerija;
