import React, { useState, useEffect } from 'react';
import '../App.css';
import { Typography } from '@mui/material';
import { useParams,Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faFlagUsa, faFlag } from '@fortawesome/free-solid-svg-icons';
import Avatar from '@mui/material/Avatar';

import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Dropdown } from 'react-bootstrap';
//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({
  menuItem: {
    color: 'white',
    fontSize: '20px !important',
  },
  listItem: {
    color: 'black',
    fontSize: '20px !important',
  },

  link: {
    textAlign: 'left', // ili 'start'
    marginLeft:15,
    textDecoration: 'none', // Uklonite podvučeno ispod linka ako želite
    color: 'inherit', // Naslijedite boju od roditeljskog elementa (bijela boja u ovom slučaju)
    display: 'block', // Postavite da bude blok element da bi zauzeo cijelu širinu roditeljskog elementa
    padding: '8px 0', // Dodajte malo razmaka ako je potrebno
  },
});

const NavbarMobile = () => {
  const classes = useStyles();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || 'en'
  ); // Default language is English

  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Retrieve the selected language from local storage and set it as the initial language
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    // Save the selected language to local storage
    localStorage.setItem('selectedLanguage', language);
  };


  const [open, setState] = useState(false);

  const location = useLocation();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };



  return (
    <>
    <>
    <div className="samoNaMobilnoj" style={{ marginLeft: 'auto' }}>
  <Grid container alignItems="center">
    <Grid item xs={3}>
      {/* Vaš element */}
      <IconButton
        edge="start"
        color="black"
        aria-label="open drawer"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
    </Grid>
    <Grid item xs={6} style={{ textAlign: 'center' }}>
      <Typography>Alma Ferović Fazlić</Typography>
    </Grid>
    <Grid item xs={3} style={{ textAlign: 'end' }}>
        <Link to={"/"}>
        <Avatar
        className={classes.media}
        alt="Avatar Alt Text"
        src="/assets/img/AlmaF.jpg"
        sx={{ width: 50, height: 50 }} // Prilagodite veličinu prema potrebi
      />
        </Link>
      
    </Grid>
  </Grid>
</div>

          <Drawer
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}    
      
          >
            <Box
              sx={{
               
                p: 2,
                height: 1,
                // backgroundColor: "#dbc8ff"
               
              }}
            >
              <IconButton sx={{ mb: 2 }} color="black">
                <CloseIcon onClick={toggleDrawer(false)} />
              </IconButton>
              <ul class="nav">
              <li class="nav-item"><p onClick={() => handleLanguageChange('en')} class="nav-link link-dark px-2">EN</p><span id="meni"></span></li>
              <li class="nav-item"><p onClick={() => handleLanguageChange('bs')} class="nav-link link-dark px-2">BS</p><span id="meni"></span></li>
              </ul>
              <Divider sx={{ mb: 2 }} />

              <Box sx={{ mb: 2 }}>
              <List component="nav">
      
    
      <Link to="/" className={classes.link}>
<ListItemText
  disableTypography
  primary={
    <Typography variant="body2" className={classes.listItem}>
      {t('home')}
    </Typography>
  }
/>
</Link>

<Link to="/biography" className={classes.link}>
<ListItemText
  disableTypography
  primary={
    <Typography variant="body2" className={classes.listItem}>
       {t('bio')}
    </Typography>
  }
/>
</Link>
    <Link to="/gallery" className={classes.link}>
    <ListItemText
    disableTypography
    primary={
        <Typography variant="body2" className={classes.listItem}>
        {t('gallery')}
        </Typography>
    }
    />
    </Link>
    <Link to="/news" className={classes.link}>
    <ListItemText
    disableTypography
    primary={
        <Typography variant="body2" className={classes.listItem}>
            {t('news')}
        </Typography>
    }
    />
    </Link>
    <Link to="/contact" className={classes.link}>
    <ListItemText
    disableTypography
    primary={
        <Typography variant="body2" className={classes.listItem}>
            {t('contact')}
        </Typography>
    }
    />
    </Link>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ marginRight: '25px' }}>
            <Link to="https://www.facebook.com/almaffazlic?mibextid=LQQJ4d" target="_blank">
              <FontAwesomeIcon icon={faFacebookF} size="1x" color="black" />
              </Link>
            </div>
            <div style={{ marginRight: '25px' }}>
            <Link to="https://www.instagram.com/almaferovicfazlic/" target="_blank">
              <FontAwesomeIcon icon={faInstagram} size="1x" color="black" />
              </Link>
            </div>
            <div style={{ marginRight: '25px' }}>
           
            <Link to="https://www.linkedin.com/in/alma-ferovic-fazlic-49a0a564" target="_blank">
              <FontAwesomeIcon icon={faLinkedinIn} size="1x" color="black" />
              </Link>
            </div>
            <div>
            <Link to="https://www.youtube.com/@imtmsarajevo9832?si=hzwAhAsvt56GHe2e" target="_blank">
              <FontAwesomeIcon icon={faYoutube} size="1x" color="black" />
              </Link>
            </div>
          </div>
        </List>
              </Box>



            </Box>
          </Drawer>
          </>
    </>

  );
};

export default NavbarMobile;
