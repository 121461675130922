import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { faClock } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import WordPressImage from './WordPressImage';
import parse from 'html-react-parser'; // Dodajte ovo
import { Link } from 'react-router-dom';
import { FadeLoader } from "react-spinners";


const useStyles = makeStyles({
  gridItem: {
    height: '100vh',
    backgroundColor: "#1a191e",
    paddingTop: "60px",
  },
  naslov: {
    color: "white",
    textAlign: 'left',
  },
  slikaVijest: {
    height: "100%",
    minHeight: "300px",
  },
  slikaVijestNaslov: {
    color: "white",
    textAlign: 'left',
  },
  slikaVijestTekst: {
    color: "#b0b0b0",
    textAlign: 'justify',
  }
});

const FeaturedNews = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const storedLanguage = localStorage.getItem('selectedLanguage');
  const [posts, setPosts] = useState([]);
  const apiUrl = 'https://app.almaf.ba/wp-json/wp/v2/posts';

  useEffect(() => {
    // Definiramo funkciju za izradu GET zahtjeva
    const fetchPosts = async () => {
      try {
        const response = await axios.get(apiUrl);
        setPosts(response.data); // Postavljamo dohvaćene postove u state
        setLoading(false);
      } catch (error) {
        console.error('There was a problem with the request:', error);
      }
    };

    // Pozivamo funkciju za dohvaćanje postova kada komponenta bude montirana
    fetchPosts();
  }, []); // Prazan niz ovisnosti znači da će se useEffect izvršiti samo jednom (kao componentDidMount)

  // console.log(posts)
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  };
  return (
    <div style={{ backgroundColor: "#1a191e", paddingLeft: '0px !important', width:"100%" }}>
      
      <Typography gutterBottom variant="h4" component="div" className={classes.naslov}>{t('news')}</Typography>
      {loading ? (
      <div
      className="text-center"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
      }}
    >
      <FadeLoader color={"#123abc"} loading={loading} size={100} />
    </div>
  ) : (
    <>
      {posts && posts?.slice(0, 2).map((e) => {
  return (
    <Card sx={{ maxWidth: "100%" }}  xs={12} style={{ backgroundColor: "#1a191e", paddingLeft: '0px !important' }}>
      {/* Dodajte WordPressImage komponentu ovdje i proslijedite joj attachment ID */}
      <WordPressImage attachmentId={e.featured_media} size={'99%'}/>

      <CardContent>
        <Typography gutterBottom variant="h5" component="div" className={classes.slikaVijestNaslov}>
        {storedLanguage === 'en' ? parse(e?.acf?.naslov) :
                        parse(e.title.rendered).substring(0, 73)}{e.title.rendered.length > 73 && '...'}
          {/* {parse(e.title.rendered)} */}
        </Typography>
        <Typography className={classes.slikaVijestTekst}>
        {storedLanguage === 'en' ? parse(e?.acf?.opis.slice(0, 200) + (e?.acf?.opis.length > 200 ? '...' : '')) :
                        parse(e.content.rendered.slice(0, 200) + (e.content.rendered.length > 200 ? '...' : ''))
                        }
          {/* {parse(e.content.rendered.slice(0, 200) + (e.content.rendered.length > 200 ? '...' : ''))} */}
        </Typography>
        <Typography className={classes.slikaVijestTekst}>{formatDate(e.date)}</Typography>
      </CardContent>
      <CardActions>
        {/* Postavljanje poveznice na rutu za prikaz pojedinosti o vijestima */}
        <Link to={`/news/${e.id}`}>
          <Button variant="contained" style={{ backgroundColor: "#e51130" }}>{t('readMore')}</Button>
        </Link>
      </CardActions>
    </Card>
  );
    })}
  </>)}
    </div>
  );
}

export default FeaturedNews;
