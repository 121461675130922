import React, { useState, useEffect } from 'react';
import '../App.css';
import { Typography } from '@mui/material';
import { useParams,Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagUsa, faFlag } from '@fortawesome/free-solid-svg-icons';


import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Dropdown } from 'react-bootstrap';
//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";


const useStyles = makeStyles({
  menuItem: {
    color: 'white',
    fontSize: '20px !important',
  },
  listItem: {
    color: 'white',
    fontSize: '20px !important',
  },

  link: {
    textAlign: 'left', // ili 'start'
    marginLeft:15,
    textDecoration: 'none', // Uklonite podvučeno ispod linka ako želite
    color: 'inherit', // Naslijedite boju od roditeljskog elementa (bijela boja u ovom slučaju)
    display: 'block', // Postavite da bude blok element da bi zauzeo cijelu širinu roditeljskog elementa
    padding: '8px 0', // Dodajte malo razmaka ako je potrebno
  },
});

const Navbar = () => {
  const classes = useStyles();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || 'en'
  ); // Default language is English

  const { t, i18n } = useTranslation();

  useEffect(() => {
    // Retrieve the selected language from local storage and set it as the initial language
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    // Save the selected language to local storage
    localStorage.setItem('selectedLanguage', language);
  };


  const [open, setState] = useState(false);

  const location = useLocation();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };



  return (
    <>
  <div className='webVarijanta'>
  <div className="font-primary">
      
      <List component="nav">
      
    
        <Link to="/" className={classes.link}>
  <ListItemText
    disableTypography
    primary={
      <Typography variant="body2" className={classes.listItem}>
        {t('home')}
      </Typography>
    }
  />
</Link>
  
  <Link to="/biography" className={classes.link}>
  <ListItemText
    disableTypography
    primary={
      <Typography variant="body2" className={classes.listItem}>
         {t('bio')}
      </Typography>
    }
  />
</Link>
<Link to="/gallery" className={classes.link}>
  <ListItemText
    disableTypography
    primary={
      <Typography variant="body2" className={classes.listItem}>
         {t('gallery')}
      </Typography>
    }
  />
</Link>
<Link to="/news" className={classes.link}>
  <ListItemText
    disableTypography
    primary={
      <Typography variant="body2" className={classes.listItem}>
           {t('news')}
      </Typography>
    }
  />
</Link>
<Link to="/contact" className={classes.link}>
  <ListItemText
    disableTypography
    primary={
      <Typography variant="body2" className={classes.listItem}>
            {t('contact')}
      </Typography>
    }
  />
</Link>
  
      </List>
      <div>
        <button onClick={() => handleLanguageChange('en')}>
          <FontAwesomeIcon icon={faFlagUsa} /> English
        </button>
        <button onClick={() => handleLanguageChange('bs')}>
          <FontAwesomeIcon icon={faFlag} /> Bosnian
        </button>
      </div>
    </div>
  </div>
    </>

  );
};

export default Navbar;
