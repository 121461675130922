import React from "react";
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import MyImageGallery from "./Galerija";
import { useTranslation } from 'react-i18next';
import YouTubeVideoBoxes from "./YoutubeBox";
import ContactForm from "./Contact";
import ContactFrontForm from "./ConstactFront";


const useStyles = makeStyles({
  gridItem: {
    height: '100vh', 
    backgroundColor:"#1a191e",
    paddingTop:"60px",
    
  },
  naslov:{
    color:"white"
  },
  slikaVijest:{
    height: "100%",
    minHeight: "300px",
  },
  slikaVijestNaslov:{
    color:"white",
    textAlign:'left',
  },
  slikaVijestTekst:{
    color: "#b0b0b0",
    textAlign: 'justify',

  }
});

const MultiMediaComponents = () =>{
    const { t} = useTranslation();
    const classes = useStyles();
    return(
    <>
    {/* <iframe src="https://www.youtube.com/embed?listType=user_uploads&list=InstitutMTM" width="480" height="400"></iframe>
    */}
   <YouTubeVideoBoxes/>
    {/* <Typography gutterBottom variant="h4" component="div" className={classes.naslov}>
    {t('gallery')}
   </Typography> */}
   {/* <MyImageGallery/> */}
   <ContactFrontForm/>
    </>
    )
}

export default MultiMediaComponents;