import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import WordPressImage from './WordPressImage';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser'; // Dodajte ovo

const useStyles = makeStyles({
  naslov: {
    color: 'white',
    textAlign: 'left',
  },
  slikaVijest: {
    height: 'auto',
    maxWidth: '100%',
  },
  slikaVijestNaslov: {
    color: 'white',
    textAlign: 'left',
  },
  slikaVijestTekst: {
    color: '#b0b0b0',
    textAlign: 'justify',
  },
});

const NewsDetail = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  let { id } = useParams();
  const [post, setPost] = useState(null);
//   const postId = 10 // Pretpostavka da je ID posta dostupan preko parametra rute
const storedLanguage = localStorage.getItem('selectedLanguage');
  const apiUrl = `https://app.almaf.ba/wp-json/wp/v2/posts/${id}`;

  useEffect(() => {
    // Definiramo funkciju za izradu GET zahtjeva
    const fetchPost = async () => {
      try {
        const response = await axios.get(apiUrl);
        setPost(response.data); // Postavljamo dohvaćeni post u state
      } catch (error) {
        console.error('There was a problem with the request:', error);
      }
    };

    // Pozivamo funkciju za dohvaćanje posta kada komponenta bude montirana
    fetchPost();
  }, [apiUrl]); // Ovisnost o URL-u posta

  if (!post) {
    // Ako post nije dohvaćen, možete prikazati neki indikator učitavanja
    return <div>Loading...</div>;
  }
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  };
  return (
    <div className="mobVer" style={{ backgroundColor: '#1a191e', marginLeft:'25%', padding:60, height:"100%",minHeight: '100vh', }}>
      {/* <Typography gutterBottom variant="h4" component="div" className={classes.naslov}>
        {t('news')}
      </Typography> */}
      <WordPressImage attachmentId={post.featured_media} size={'50%'} maxh={"100%"} maxw={"100%"}/>
      {/* <img
        className={classes.slikaVijest}
        src={post.featured_image_url} // Zamijenite ovo s odgovarajućim poljem za URL slike
        alt={post.title.rendered}
      /> */}
      <Typography gutterBottom variant="h5" component="div" className={classes.slikaVijestNaslov}>
      {storedLanguage === 'en' ? parse(post.acf?.naslov) : parse(post.title.rendered)}
      {/* {parse(post.title.rendered)} */}
      </Typography>
      <Typography className={classes.slikaVijestTekst}>{formatDate(post.date)}</Typography>
      <Typography className={classes.slikaVijestTekst}>
      {/* {parse(post.content.rendered)} */}
      {storedLanguage === 'en' ? parse(post?.acf?.opis) : parse(post.content.rendered)}
      </Typography>
     
    </div>
  );
};

export default NewsDetail;
