import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { faClock } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import WordPressImage from '../components/WordPressImage';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
// import CircularProgress from '@mui/material/CircularProgress';
import { FadeLoader } from "react-spinners"; 

const useStyles = makeStyles({
  gridItem: {
    height: '100vh',
    backgroundColor: "#1a191e",
    paddingTop: "60px",
    
  },
  naslov: {
    color: "white",
    textAlign: 'left',
  },
  slikaVijest: {
    height: "100%",
    minHeight: "300px",
  },
  slikaVijestNaslov: {
    color: "white",
    textAlign: 'left',
  },
  slikaVijestTekst: {
    color: "#b0b0b0",
    textAlign: 'justify',
  },
  gridContainer: {
    minHeight: '100vh', // Postavite visinu kontejnera na visinu ekrana

  },
  gridItemLeft: {
    position: "fixed",
    height: '100%', // Fiksna visina za Grid sa xs={3}
    backgroundColor: 'lightgray', // Boja pozadine po potrebi
  },
  gridItemRight: {
    marginLeft: '25%', // Margina sa lijeve strane, prilagodite po potrebi
    // paddingLeft:'0px !important',
    backgroundColor:"#1a191e",
    minHeight: '102vh !important',
    height: '100% !important', 
    display:'block',
    padding:20,
  },

});

const AllNews = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [posts, setPosts] = useState([]);
  const [visiblePosts, setVisiblePosts] = useState(6); // Broj vidljivih vijesti
  const [loading, setIsLoading] = useState(true); // Dodajte isLoading state
  const storedLanguage = localStorage.getItem('selectedLanguage');

  const apiUrl = 'https://app.almaf.ba/wp-json/wp/v2/posts';

  // useEffect(() => {
  //   // Retrieve the selected language from local storage and set it as the initial language
  //   const storedLanguage = localStorage.getItem('selectedLanguage');
  //   // if (storedLanguage) {
  //   //   setSelectedLanguage(storedLanguage);
  //   //   i18n.changeLanguage(storedLanguage);
  //   // }
  // }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(apiUrl);
        setPosts(response.data);
        setIsLoading(false); 
      } catch (error) {
        console.error('There was a problem with the request:', error);
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  // console.log(posts[0]?.acf?.naslov)
  const loadMorePosts = () => {
    setVisiblePosts(visiblePosts + 3); // Dodajte još 4 vijesti
  };  
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
    return formattedDate;
  };
  
  return (
    <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: '#1a191e',marginLeft:"24%" }} className="mobileVerVijesti">
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12} className={`${classes.gridItemRight} webVerzijaVijesti`}>
          <Typography gutterBottom variant="h4" component="div" className={classes.naslov}>
            {t('news')}
          </Typography>
          {loading ? (
      <div
      className="text-center"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70vh', // Postavite visinu na visinu ekrana (100% viewport visine)
      }}
    >
      <FadeLoader color={"#123abc"} loading={loading} size={100} />
    </div>
  ) : (
            <Grid container spacing={2}>
              {posts.slice(0, visiblePosts).map((e) => (
                <Grid item xs={12} sm={6} md={6} key={e.id}>
                  <Card sx={{ maxWidth: "100%", backgroundColor: "#1a191e", paddingLeft: '0px !important' }}>
                    <WordPressImage attachmentId={e.featured_media} size={'99%'} />

                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div" className={classes.slikaVijestNaslov}>
                        {/* {console.log(e.acf)} */}
                        {storedLanguage === 'en' ? parse(e?.acf?.naslov.slice(0, 150) + (e?.acf?.naslov.length > 150 ? '...' : '')) :
                        // {storedLanguage === 'en' ? parse(e?.acf?.naslov) :
                        parse(e.title.rendered).substring(0, 73)}{e.title.rendered.length > 73 && '...'}
                      </Typography>
                      <Typography className={classes.slikaVijestTekst}>
                        {/* {parse(e?.acf?.opis.slice(0, 200) + (e?.acf?.opis.length > 200 ? '...' : ''))} */}
                        
                        {storedLanguage === 'en' ? parse(e?.acf?.opis.slice(0, 200) + (e?.acf?.opis.length > 200 ? '...' : '')) :
                        parse(e.content.rendered.slice(0, 200) + (e.content.rendered.length > 200 ? '...' : ''))
                        }
                      </Typography>
                      <Typography className={classes.slikaVijestTekst}>{formatDate(e.date)}</Typography>
                    </CardContent>
                    <CardActions>
                      <Link to={`/news/${e.id}`}>
                        <Button variant="contained" style={{ backgroundColor: "#e51130" }}>
                          {t('readMore')}
                        </Button>
                      </Link>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
             )}
          {visiblePosts < posts.length && (
            <Button
              variant="contained"
              style={{ backgroundColor: "#1976d2", marginTop: "20px" }}
              onClick={loadMorePosts}
            >
              {t('loadmore')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllNews;

