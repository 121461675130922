import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from './components/LeftSidebar';
import Home from './screen/Home';
import NewsDetail from './components/NewsDetail';
import Biografija from './screen/Biography';
import Galerija from './screen/Galerija';
import ContactForm from './components/Contact';
import AllNews from './screen/News';
import { LoaderProvider } from "./context/LoaderContext"; // Uvezite LoaderProvider
import NavbarMobile from './navigation/mobileMenu';

function App() {
  // const [loading, setLoading] = useState(true); // Inicijalno postavite loading na true

  // // Simulacija učitavanja podataka
  // useEffect(() => {
  //   setTimeout(() => {
  //     // Nakon što se svi podaci učitaju, postavite loading na false
  //     setLoading(false);
  //   }, 6000); // Simulirajte učitavanje 2 sekunde (prilagodite ovo vašim potrebama)
  // }, []);

  return (
    <div className="App" style={{ display: 'flex' }}>
      <Router>
        {/* <LoaderProvider>  */}
          {/* Ovdje omotajte komponentu LoaderProvider */}
          <Navbar />
        
          <Routes style={{ flex: 1 }}>
            <Route path="/" element={<Home />} />
            <Route path="/biography" element={<Biografija />} />
            <Route path="/news/:id" element={<NewsDetail />} />
            <Route path="/gallery" element={<Galerija />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/news" element={<AllNews />} />
            {/* Dodajte druge rute ovdje */}
          </Routes>
        {/* </LoaderProvider> */}
      </Router>
    </div>
  );
}

export default App;
