import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
// import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import parse from 'html-react-parser'; // Dodajte ovo

const useStyles = makeStyles({
  gridContainer: {
    minHeight: '100vh', // Postavite visinu kontejnera na visinu ekrana
  },
  gridItemLeft: {
    position: "fixed",
    height: '100%', // Fiksna visina za Grid sa xs={3}
    backgroundColor: 'lightgray', // Boja pozadine po potrebi
  },
  gridItemRight: {
    marginLeft: '20%', // Margina sa lijeve strane, prilagodite po potrebi
    // paddingLeft:'0px !important',
    backgroundColor:"#1a191e",
    minHeight: '102vh !important',
    height: '100% !important', 
    display:'block',
    padding:20
  },
  slikaVijestNaslov: {
    color: "white",
    textAlign: 'left',
  },
  slikaVijestTekst: {
    color: "#b0b0b0",
    textAlign: 'justify',
  }
});



const Biografija = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  // let { id } = useParams();
  const [post, setPost] = useState(null);
//   const postId = 10 // Pretpostavka da je ID posta dostupan preko parametra rute
  const id = 13;
  const apiUrl = `https://app.almaf.ba/wp-json/wp/v2/pages/2`;
  const storedLanguage = localStorage.getItem('selectedLanguage');
  useEffect(() => {
    // Definiramo funkciju za izradu GET zahtjeva
    const fetchPost = async () => {
      try {
        const response = await axios.get(apiUrl);
        setPost(response.data); // Postavljamo dohvaćeni post u state
      } catch (error) {
        console.error('There was a problem with the request:', error);
      }
    };

    // Pozivamo funkciju za dohvaćanje posta kada komponenta bude montirana
    fetchPost();
  }, [apiUrl]); // Ovisnost o URL-u posta

  // console.log(post)
  if (!post) {
    // Ako post nije dohvaćen, možete prikazati neki indikator učitavanja
    return <div>Loading...</div>;
  }


  return (
    <Box sx={{ flexGrow: 1 }} style={{backgroundColor: '#1a191e', marginLeft:"22%"}}>
      <Grid container spacing={2} className={classes.gridContainer}>
        {/* <Grid item xs={3} className={classes.gridItemLeft}>
          <LeftSidebar/>
        </Grid> */}
        <Grid item xs={12} className={`${classes.gridItemRight} webVerzijaBiografija`}>
          {/* <RigtSidebar/> */}
          <CardContent>
        <Typography gutterBottom variant="h5" component="div" className={classes.slikaVijestNaslov}>
        {storedLanguage === 'en' ? parse(post.acf?.naslov) : parse(post.title.rendered)}
          {/* {parse(post.title.rendered)} */}
        </Typography>
        <Typography className={classes.slikaVijestTekst}>
          {/* {parse(post.content.rendered)} */}
          {storedLanguage === 'en' ? parse(post?.acf?.opis) : parse(post.content.rendered)}
        </Typography>
        {/* <Typography className={classes.slikaVijestTekst}>{post.date}</Typography> */}
      </CardContent>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Biografija;

