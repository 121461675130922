import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LeftSidebar from "../components/LeftSidebar";
import RigtSidebar from "../components/RightSidebar";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  gridContainer: {
    minHeight: '100vh', // Postavite visinu kontejnera na visinu ekrana
  },
  gridItemLeft: {
    position: "fixed",
    height: '100%', // Fiksna visina za Grid sa xs={3}
    backgroundColor: 'lightgray', // Boja pozadine po potrebi
  },
  gridItemRight: {
    marginLeft: '25%', // Margina sa lijeve strane, prilagodite po potrebi
    paddingLeft:'0px !important',
    backgroundColor:"#1a191e",
    minHeight: '100vh !important',
    height: '100% !important', 
    display:'block'
  },
});

const Home = () => {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }} style={{backgroundColor: '#1a191e'}}>
      <Grid container xs={12} spacing={2} className={classes.gridContainer}>
        {/* <Grid item xs={3} className={classes.gridItemLeft}>
          <LeftSidebar/>
        </Grid> */}
        <Grid item xs={12} className={classes.gridItemRight}>
          <RigtSidebar/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Home;
