import React from "react";
import Stack from '@mui/material/Stack';
import '../App.css'
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import Navbar from "../navigation/navbarMenu";
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import NavbarMobile from "../navigation/mobileMenu";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({
  card: {
    padding: '45px',
    maxWidth: '20%', // Ograničite maksimalnu širinu kartice na 20%
    backgroundColor: "#1a191e",
    minHeight: '100vh',
    height: '100%',
    position: 'fixed', // Postavite fiksni položaj
    left: 0, // Postavite na lijevu stranu
    top: 0, // Postavite na vrh
    zIndex: 1, // Postavite visoku dubinu za prikaz iznad ostalog sadržaja
  },
  cardHidden: {
    display: 'none',
  },
  media: {
    height: 'auto',
    minWidth: "300px",
    width: "100%",
    borderRadius: "13% !important",
  },
  cardElement: {
    backgroundColor: "#1a191e !important",
  },
  naslovAlma: {
    borderBottom: "2px solid #E4112F",
    paddingBottom: "10px",
    color: "#fff!important",
    marginTop: "1 rem!important",
    fontSize: '33px !important',
  }
});

const LeftSidebar = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 600px)'); // Promenite vrednost na željenu širinu za mobilne uređaje

  return (
    <>
     <Grid item xs={12} sm={12} md={6} lg={6} className="webVarijanta">
     <Stack direction="row" spacing={2} className={isMobile ? classes.cardHidden : classes.card}>
      <Card className={classes.cardElement}>
        <img
          className={classes.media}
          src="/assets/img/AlmaF.jpg"
          alt="Image Alt Text"
        />
        <CardContent>
          <Typography className={classes.naslovAlma} variant="body2" color="textSecondary" component="p">
            Alma Ferović Fazlić
          </Typography>
        </CardContent>
        <Card className={classes.cardElement}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ marginRight: '25px' }}>
            <Link to="https://www.facebook.com/almaffazlic?mibextid=LQQJ4d" target="_blank">
              <FontAwesomeIcon icon={faFacebookF} size="1x" color="white" />
              </Link>
            </div>
            <div style={{ marginRight: '25px' }}>
            <Link to="https://www.instagram.com/almaferovicfazlic/" target="_blank">
              <FontAwesomeIcon icon={faInstagram} size="1x" color="white" />
              </Link>
            </div>
            <div style={{ marginRight: '25px' }}>
           
            <Link to="https://www.linkedin.com/in/alma-ferovic-fazlic-49a0a564" target="_blank">
              <FontAwesomeIcon icon={faLinkedinIn} size="1x" color="white" />
              </Link>
            </div>
            <div>
            <Link to="https://www.youtube.com/@imtmsarajevo9832?si=hzwAhAsvt56GHe2e" target="_blank">
              <FontAwesomeIcon icon={faYoutube} size="1x" color="white" />
              </Link>
            </div>
          </div>
        </Card>
        <Navbar />
      </Card>
    </Stack>
     </Grid>
  
     <div className="mobVarijanta" >
     {/* <Grid item xs={12} sm={12} md={6} lg={6} className="webVarijanta"> */}
     <NavbarMobile/>
     {/* </Grid> */}
     </div>
     </>
  )
}

export default LeftSidebar;
