import React, { useEffect, useState } from 'react';
import axios from 'axios';

function WordPressImage({ attachmentId, size, maxw, maxh }) { // Dodajte attachmentId kao prop
  const [imageUrl, setImageUrl] = useState('');
  const apiUrl = `https://app.almaf.ba/wp-json/wp/v2/media/${attachmentId}`;

  useEffect(() => {
    // Definiramo funkciju za izradu GET zahtjeva za dohvat informacija o prilogu
    const fetchImageInfo = async () => {
      try {
        const response = await axios.get(apiUrl);
        const imageInfo = response.data;
        const imageUrl = imageInfo.source_url;
        setImageUrl(imageUrl); // Postavljamo URL slike u stanje komponente
      } catch (error) {
        console.error('There was a problem with the request:', error);
      }
    };

    // Pozivamo funkciju za dohvaćanje informacija o prilogu kada se komponenta montira
    fetchImageInfo();
  }, [apiUrl]); // Ovdje je apiUrl ovisnost kako bi useEffect reagirao na promjene URL-a

  return <img src={imageUrl} alt="WordPress Image"  className="slikaMob" style={{width: size, maxWidth: maxw, maxHeight: maxh}}/>; 
}

export default WordPressImage;
